<template>
    <a-drawer
        v-model:visible="visible"
        :closable="false"
        @close="handleCancel"
        title="质检方案详情"
    >
        <template #extra>
            <a-space direction="horizontal" :size="8">
                <a-button @click="handleCancel">取消</a-button>
                <a-button type="primary" @click="handleCancel">关闭</a-button>
            </a-space>
        </template>
        <a-descriptions title="基础信息" :column="2">
            <a-descriptions-item label="工序编码">
                {{ detailRow?.basWorkStepCode }}
            </a-descriptions-item>
            <a-descriptions-item label="工序名称">
                {{ detailRow?.basWorkStepName }}
            </a-descriptions-item>
            <a-descriptions-item label="质检方案编码">
                {{ detailRow?.basQcPlanCode }}
            </a-descriptions-item>
            <a-descriptions-item label="质检方案名称">
                {{ detailRow?.basQcPlanName }}
            </a-descriptions-item>
        </a-descriptions>
        <a-descriptions title="质检项信息"></a-descriptions>
        <a-table
            :columns="columns"
            :data-source="
                detailRow?.basQcPlanItemInfoList?.map(item => ({
                    ...item,
                    ...item.basQcItem,
                }))
            "
            bordered
            size="small"
            :pagination="false"
        >
            <template #bodyCell="{ column, record, index }">
                <template v-if="column.dataIndex === 'index'">
                    {{ index + 1 }}
                </template>
                <template v-if="column.dataIndex === 'resultList'">
                    <div>
                        <a-table
                            style="margin: 0"
                            :dataSource="record.testingDetails"
                            size="small"
                            :pagination="false"
                            :columns="resultListColumns"
                        />
                    </div>
                </template>
            </template>
        </a-table>
    </a-drawer>
</template>

<script>
import { defineComponent, reactive, toRefs, watch } from "vue";

const columns = [
    {
        title: "序号",
        dataIndex: "index",
        width: 60,
    },
    {
        title: "质检项编码",
        dataIndex: "code",
    },
    {
        title: "质检项名称",
        dataIndex: "name",
    },
    {
        title: "质检项属性",
        dataIndex: "typeZh",
    },
    {
        title: "检验标准",
        dataIndex: "testingStandards",
    },
    {
        title: "结果值列表",
        dataIndex: "resultList",
    },
];
export default defineComponent({
    props: {
        detailRow: {
            default: null,
        },
    },
    setup(props, { emit }) {
        const state = reactive({
            visible: false,
            tableData: [],
        });

        const handleCancel = () => {
            emit("update:detailRow", null);
        };

        watch(
            () => props.detailRow,
            newVal => {
                state.visible = Boolean(newVal);
                if (newVal) {
                }
            }
        );

        return {
            columns,
            ...toRefs(state),

            handleCancel,

            resultListColumns: [
                {
                    title: "名称",
                    dataIndex: "label",
                },
                {
                    title: "标准值",
                    dataIndex: "standardValue",
                },
                {
                    title: "上限",
                    dataIndex: "upperLimit",
                },
                {
                    title: "下限",
                    dataIndex: "lowerLimit",
                },
                {
                    title: "单位",
                    dataIndex: "unit",
                },
            ],
        };
    },
});
</script>

<style lang="less" scoped>
.filebox {
    display: flex;
    flex-wrap: wrap;
    a {
        margin-right: 10px;
    }
}
</style>
