<template>
    <a-drawer
        v-model:visible="visible"
        :closable="false"
        @close="handleCancel"
        title="集成工序检验单详情"
    >
        <template #extra>
            <a-space direction="horizontal" :size="8">
                <a-button @click="handleCancel">取消</a-button>
                <a-button type="primary" @click="handleCancel">关闭</a-button>
            </a-space>
        </template>
        <a-tabs v-model:value="activeTabKey">
            <a-tab-pane :key="1" tab="基础信息">
                <a-descriptions title="">
                    <a-descriptions-item label="检验单号">
                        {{ orderInfo?.code }}
                    </a-descriptions-item>

                    <a-descriptions-item label="单据状态" :span="2">
                        <span :style="{ color: getStatusColor(orderInfo?.status) }">
                            {{ orderInfo?.statusZh }}
                        </span>
                    </a-descriptions-item>

                    <a-descriptions-item label="订单号">
                        {{ orderInfo?.produceOrderCode }}
                    </a-descriptions-item>

                    <a-descriptions-item label="项目编码">
                        {{ orderInfo?.projectCode }}
                    </a-descriptions-item>

                    <a-descriptions-item label="项目名称">
                        {{ orderInfo?.projectName }}
                    </a-descriptions-item>

                    <a-descriptions-item label="产品编码">
                        {{ orderInfo?.productCode }}
                    </a-descriptions-item>

                    <a-descriptions-item label="产品名称">
                        {{ orderInfo?.productName }}
                    </a-descriptions-item>

                    <a-descriptions-item label="规格型号">
                        {{ orderInfo?.spec }}
                    </a-descriptions-item>

                    <a-descriptions-item label="集成编号" :span="3">
                        {{ orderInfo?.containerNo }}
                    </a-descriptions-item>

                    <a-descriptions-item label="备注" :span="3">
                        {{ orderInfo?.remark }}
                    </a-descriptions-item>

                    <a-descriptions-item label="异常时长">
                        {{ orderInfo?.exceptionDuration }} h
                    </a-descriptions-item>

                    <a-descriptions-item label="检验时长">
                        {{ orderInfo?.checkDuration }} h
                    </a-descriptions-item>
                </a-descriptions>
            </a-tab-pane>
            <a-tab-pane :key="2" tab="质检方案信息" force-render>
                <PlanInfo :planInfo="planInfo" />
            </a-tab-pane>
            <a-tab-pane :key="3" tab="工序检验记录">
                <stepCheckRecord :data="stepCheckData" />
            </a-tab-pane>
            <a-tab-pane :key="4" tab="操作记录">
                <OperateRecord :data="operateData" />
            </a-tab-pane>
        </a-tabs>
    </a-drawer>
</template>
<script>
import { defineComponent, ref, watch, reactive, toRefs, computed } from "vue";
import { apiJCStepCheckOrderDetail } from "@/api/qualityCheck";
import { getStatusColor } from "@/utils/common";
import OperateRecord from "./operateRecord";
import PlanInfo from "./planInfo.vue";
import StepCheckRecord from "./stepCheckRecord.vue";

export default defineComponent({
    props: ["detailId"],
    emits: ["update:detailId"],
    components: {
        OperateRecord,
        PlanInfo,
        StepCheckRecord,
    },
    setup(props, { emit }) {
        const state = reactive({
            loading: false,
            visible: false,
            tableData: [],
            activeTabKey: 1,
            orderInfo: null,
            planInfo: null,
            stepCheckData: [],
            operateData: [],
        });

        const handleCancel = () => {
            emit("update:detailId");
        };

        const getDetailData = async id => {
            let res = await apiJCStepCheckOrderDetail(id);
            if (res.status === "SUCCESS") {
                state.orderInfo = res.data;
                const { jcStepCheckListInfoVo2PlanItems, jcStepCheckInfos, operates } = res?.data;
                state.planInfo = jcStepCheckListInfoVo2PlanItems;
                state.stepCheckData = jcStepCheckInfos;
                state.operateData = operates;
            }
        };

        watch(
            () => props.detailId,
            newVal => {
                state.visible = Boolean(newVal);
                if (newVal) {
                    getDetailData(newVal);
                }
            }
        );

        return {
            ...toRefs(state),
            handleCancel,
            getStatusColor,
        };
    },
});
</script>

<style lang="less" scoped>
.title {
    margin-bottom: 10px;
}
.operate-content {
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
</style>
