<template>
    <PlanInfoDetail v-model:detailRow="detailRow" />
    <a-table :columns="columns" :data-source="tableData" bordered size="small" :pagination="false">
        <template #bodyCell="{ column, record, index }">
            <template v-if="column.dataIndex === 'index'">
                {{ index + 1 }}
            </template>
            <template v-if="column.dataIndex === 'operate'">
                <a @click="() => (detailRow = record)">详情</a>
            </template>
        </template>
    </a-table>
</template>

<script>
import { computed, defineComponent, reactive, toRefs } from "vue";
import { fileURL } from "@/utils/config";
import PlanInfoDetail from "./planInfoDetail";
const columns = [
    {
        title: "序号",
        dataIndex: "index",
        width: 60,
    },
    {
        title: "工序编码",
        dataIndex: "basWorkStepCode",
    },
    {
        title: "工序名称",
        dataIndex: "basWorkStepName",
    },
    {
        title: "质检方案编码",
        dataIndex: "basQcPlanCode",
    },
    {
        title: "质检方案名称",
        dataIndex: "basQcPlanName",
    },
    {
        title: "操作",
        dataIndex: "operate",
    },
];
export default defineComponent({
    props: {
        planInfo: {
            default: null,
        },
    },
    components: {
        PlanInfoDetail,
    },
    setup(props) {
        const state = reactive({
            detailRow: null,
            tableData: computed(() => {
                return props?.planInfo?.map(item => ({
                    ...item,
                    ...item.testingDetails,
                    workingHours: item.standardWorkingHours?.workingHours,
                    hourUnit: item.standardWorkingHours?.unit,
                    ...item.basQcItem,
                }));
            }),
        });

        return {
            columns,
            ...toRefs(state),
            fileURL,
            resultListColumns: [
                {
                    title: "名称",
                    dataIndex: "label",
                },
                {
                    title: "标准值",
                    dataIndex: "standardValue",
                },
                {
                    title: "上限",
                    dataIndex: "upperLimit",
                },
                {
                    title: "下限",
                    dataIndex: "lowerLimit",
                },
                {
                    title: "单位",
                    dataIndex: "unit",
                },
            ],
        };
    },
});
</script>

<style lang="less" scoped>
.filebox {
    display: flex;
    flex-wrap: wrap;
    a {
        margin-right: 10px;
    }
}
</style>
