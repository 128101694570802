<template>
    <StepCheckRecordDetail v-model:detailId="detailId" />
    <a-table :columns="columns" :data-source="data" bordered size="small" :pagination="false">
        <template #bodyCell="{ column, record, index }">
            <template v-if="column.dataIndex === 'index'">
                {{ index + 1 }}
            </template>
            <template v-if="column.dataIndex === 'checkCount'">
                {{ record.checkCount }}
            </template>
            <template v-if="column.dataIndex === 'checkResultJudgment'">
                <span
                    :style="{
                        color: record?.checkResultJudgment == 'PASS' ? '#2BCE1D' : '#ff4d4f',
                    }"
                >
                    {{ record?.checkResultJudgment }}
                </span>
            </template>
            <template v-if="column.dataIndex === 'checkDuration'">
                {{ record.checkDuration ?? "0" }} h
            </template>
            <template v-if="column.dataIndex === 'checkers'">
                {{ record.checkers?.map(item => item.displayName)?.join("，") }}
            </template>
            <template v-if="column.dataIndex === 'operate'">
                <a @click="() => (detailId = record.id)">详情</a>
            </template>
        </template>
    </a-table>
</template>

<script>
import { defineComponent, reactive, toRefs } from "vue";
import StepCheckRecordDetail from "./stepCheckRecordDetail";
const columns = [
    {
        title: "序号",
        dataIndex: "index",
        width: 60,
    },
    {
        title: "工序编码",
        dataIndex: "basWorkStepCode",
    },
    {
        title: "工序名称",
        dataIndex: "basWorkStepName",
    },
    {
        title: "质检方案编码",
        dataIndex: "basQcPlanCode",
    },
    {
        title: "质检方案名称",
        dataIndex: "basQcPlanName",
    },
    {
        title: "检验次数",
        dataIndex: "checkCount",
    },
    {
        title: "结果判定",
        dataIndex: "checkResultJudgment",
    },
    {
        title: "提交时间",
        dataIndex: "submitTime",
    },
    {
        title: "提交人",
        dataIndex: "submitter",
    },
    {
        title: "检验员",
        dataIndex: "checkers",
    },
    {
        title: "异常时长",
        dataIndex: "exceptionWorkingHours",
    },
    {
        title: "执行时长",
        dataIndex: "checkDuration",
    },
    {
        title: "操作",
        dataIndex: "operate",
    },
];
export default defineComponent({
    props: {
        data: {
            default: [],
        },
        routeInfo: {
            default: null,
        },
    },
    components: {
        StepCheckRecordDetail,
    },
    setup() {
        const state = reactive({
            detailId: null,
        });

        return {
            columns,
            ...toRefs(state),
        };
    },
});
</script>
