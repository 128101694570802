<template>
    <a-drawer
        v-model:visible="visible"
        :closable="false"
        @close="handleCancel"
        title="工序检验执行详情"
    >
        <template #extra>
            <a-space direction="horizontal" :size="8">
                <a-button @click="handleCancel">取消</a-button>
                <a-button type="primary" @click="handleCancel">关闭</a-button>
            </a-space>
        </template>
        <a-descriptions title="基础信息" :column="2">
            <a-descriptions-item label="工序编码">
                {{ data?.basWorkStepCode }}
            </a-descriptions-item>
            <a-descriptions-item label="工序名称">
                {{ data?.basWorkStepName }}
            </a-descriptions-item>
            <a-descriptions-item label="质检方案编码">
                {{ data?.basQcPlanCode }}
            </a-descriptions-item>
            <a-descriptions-item label="质检方案名称">
                {{ data?.basQcPlanName }}
            </a-descriptions-item>
            <a-descriptions-item label="提交时间">
                {{ data?.submitTime }}
            </a-descriptions-item>
            <a-descriptions-item label="提交人">
                {{ data?.submitter }}
            </a-descriptions-item>
            <a-descriptions-item label="检验员">
                {{ data?.checkers?.map(item => item.displayName)?.join("，") }}
            </a-descriptions-item>
            <a-descriptions-item label="检验时长">{{ data?.checkDuration }} h</a-descriptions-item>
            <a-descriptions-item label="异常时长">
                {{ data?.exceptionWorkingHours }} h
            </a-descriptions-item>
        </a-descriptions>
        <a-descriptions title="检验信息"></a-descriptions>
        <a-table
            :columns="columns"
            :data-source="tableData"
            bordered
            size="small"
            :pagination="false"
        >
            <template #bodyCell="{ column, record, index }">
                <template v-if="column.dataIndex === 'index'">
                    {{ index + 1 }}
                </template>
                <template v-if="column.dataIndex === 'resultList'">
                    <div>
                        <a-table
                            style="margin: 0"
                            :dataSource="record.resultList"
                            size="small"
                            :pagination="false"
                            :columns="resultListColumns"
                        />
                    </div>
                </template>
                <template v-if="column.dataIndex === 'resultJudgment'">
                    <span
                        :style="{ color: record?.resultJudgment == 'PASS' ? '#2BCE1D' : '#ff4d4f' }"
                    >
                        {{ record?.resultJudgment }}
                    </span>
                </template>
                <template v-if="column.dataIndex === 'pics'">
                    <a v-if="record?.pics?.length" @click="handleViewPic(record.id, record?.pics)">
                        查看({{ record?.pics?.length }})
                    </a>
                    <span v-else style="color: rgba(0, 0, 0, 0.3)">暂无图片</span>
                    <div style="display: none" v-if="previewId == record?.id">
                        <a-image-preview-group
                            :preview="{
                                visible: Boolean(previewId == record?.id),
                                onVisibleChange: vis => (previewId = null),
                            }"
                        >
                            <a-image
                                v-for="item in record?.pics"
                                :key="item"
                                :src="`${fileURL}/download/${item}`"
                            />
                        </a-image-preview-group>
                    </div>
                </template>
            </template>
        </a-table>
    </a-drawer>
</template>

<script>
import { computed, defineComponent, reactive, toRefs, watch } from "vue";
import { fileURL } from "@/utils/config";
import { showMessage } from "@/utils/common";
import { apiJCStepCheckRecordDetail } from "@/api/qualityCheck";
const columns = [
    {
        title: "序号",
        dataIndex: "index",
        width: 60,
    },
    {
        title: "质检项编码",
        dataIndex: "code",
    },
    {
        title: "质检项名称",
        dataIndex: "name",
    },
    {
        title: "质检项属性",
        dataIndex: "typeZh",
        width: 100,
    },
    {
        title: "检验标准",
        dataIndex: "testingStandards",
    },
    {
        title: "结果值列表",
        dataIndex: "resultList",
    },
    {
        title: "结果判定",
        dataIndex: "resultJudgment",
        width: 100,
    },
    {
        title: "图片",
        dataIndex: "pics",
        width: 100,
    },
];
export default defineComponent({
    props: {
        detailId: {
            default: null,
        },
    },
    setup(props, { emit }) {
        const state = reactive({
            visible: false,
            data: null,
            previewId: null,
            tableData: [],
        });

        const getDetailData = async id => {
            let res = await apiJCStepCheckRecordDetail(id);
            if (res.status === "SUCCESS") {
                state.data = res.data;
                state.tableData = res?.data?.basQcPlanItemInfos.map(item => {
                    let resItem = res?.data?.jcStepCheckQcPlanItemOrderInfos?.find(
                        uu => uu.basQcPlanItemId == item.id
                    );
                    return {
                        ...item,
                        ...item?.basQcItem,
                        ...resItem,
                        resultJudgment: resItem?.jcStepCheckQcPlanItemJudgment?.resultJudgment,
                        pics: resItem?.jcStepCheckQcPlanItemFileIds,
                        resultList: resItem?.jcStepCheckQcPlanItemResults?.map(vv => ({
                            ...vv,
                            unit: item?.testingDetails?.find(uu => uu.label == vv.resultName)?.unit,
                        })),
                    };
                });
            }
        };

        const handleCancel = () => {
            emit("update:detailId", null);
        };

        const handleViewPic = (id, pics) => {
            if (!pics.length) return showMessage("info", "暂无图片");
            state.previewId = id;
        };

        watch(
            () => props.detailId,
            newVal => {
                state.visible = Boolean(newVal);
                if (newVal) {
                    getDetailData(newVal);
                }
            }
        );

        return {
            columns,
            ...toRefs(state),
            fileURL,
            handleCancel,
            handleViewPic,
            resultListColumns: [
                {
                    title: "名称",
                    dataIndex: "resultName",
                },
                {
                    title: "结果值",
                    dataIndex: "resultValue",
                },
                {
                    title: "单位",
                    dataIndex: "unit",
                },
            ],
        };
    },
});
</script>

<style lang="less" scoped>
.filebox {
    display: flex;
    flex-wrap: wrap;
    a {
        margin-right: 10px;
    }
}
</style>
